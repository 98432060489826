import React, { useState } from 'react';
import './App.css';

function App() {
  const [input, setInput] = useState('');
  const [verse, setVerse] = useState('');
  const [showInput, setShowInput] = useState(true);

  const handleInputChange = (e) => {
    setInput(e.target.value);
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight - 20}px`;
  };

  const fetchVerse = async () => {
    setInput('');
    setShowInput(false);
    setVerse('Loading...');

    const response = await fetch('https://api.comfortword.com/verse', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ description: input }),
    });
    const data = await response.json();
    setVerse(data.verse);
  };

  const handleGoBack = () => {
    setVerse('');
    setShowInput(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchVerse();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      fetchVerse();
    }
  };  

  return (
    <div className="App">
      <div className={`content ${!showInput && 'content-hidden'}`}>
        <form className="input-form" onSubmit={handleSubmit}>
          <textarea
            className="textarea"
            placeholder="How are you feeling?"
            value={input}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          <button
            type="submit"
            className={`submit-button ${!input && 'button-hidden'}`}
          >
            Find Comfort
          </button>
        </form>
      </div>
      {!showInput && (
        <div className="verse-container">
          <p className="verse">{verse}</p>
          <button className="go-back-button" onClick={handleGoBack}>
            ← Go back
          </button>
        </div>
      )}
    </div>
  );
}

export default App;